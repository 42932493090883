import * as React from 'react';

import type { HeadProps, PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { CtaBlock } from 'stile-shared/src/components/2020/blocks/CtaBlock';
import { P } from 'stile-shared/src/components/2020/common/Primitives';

import { PageLayout } from 'templates/PageLayout';
import { SEO } from 'templates/SEO';

import { ResourceAccessBlock } from 'components/common/ResourceAccessBlock';

export function Head(props: HeadProps) {
  return (
    <SEO
      title="Instructional Design Review of Stile"
      description="Conducted by Johns Hopkins University's Center for Research and Reform in Education"
      slug={props.location.pathname}
    />
  );
}

function JohnsHopkinsPage(props: PageProps) {
  return (
    <PageLayout {...props}>
      <ResourceAccessBlock
        title="Instructional Design Review of Stile"
        subtitle="Conducted by Johns Hopkins University's Center for Research and Reform in Education"
        id="JOHNS_HOPKINS"
        redirectUrl="https://stileapp.com/go/john-hopkins-report"
        image={
          <StaticImage
            src="../../../../stile-shared/assets/images/resources/johns_hopkins.png"
            alt=""
          />
        }
      >
        <P>
          In June 2024, Alan J. Reid (PhD) and Steve Ross (PhD) from John Hopkins University’s
          Centre for Research and Reform in Education completed a design review using a broad
          sampling of curricula developed by Stile for middle and high school aged learners. These
          materials included access to the digital platform, course samples, lesson plans, and
          teacher guides. This evaluation considers the learning products collectively from an
          instructional design perspective that investigates their program design framework, content
          selection, design, and development, evaluation, and material interface.
        </P>
        <P>
          The researchers at John Hopkins University found the curriculum to be grounded in rigorous
          research and had a meticulously designed logic model. Download the review to read through
          their analysis yourself!
        </P>
      </ResourceAccessBlock>

      <CtaBlock />
    </PageLayout>
  );
}

export default JohnsHopkinsPage;
